/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./benchmark";
 *   Alpine.data('benchmark', benchmark);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <form x-data="benchmark()" />
 *
 * @returns {{
 *    sizeChanged(): void,
 *    submit(): void,
 *    renderChart: boolean,
 *    benchmarkData: {
 *      audience: string,
 *      size: string,
 *      industry: string
 *    },
 *    origData: *[],
 *    showCalculate: boolean,
 *    industryData: *[],
 *    industryChanged(): void,
 *    chartData: {
 *      mkbv: string,
 *      frt: string,
 *      mes: string,
 *      csat: string,
 *      mtv: string,
 *      sup: string
 *    },
 *    dropdownOptions: *[],
 *    submitted: boolean,
 *    showSize: boolean,
 *    audienceData: *[],
 *    audienceChanged(): void,
 *    smoothScroll(): void,
 *    reset(): void,
 *    sizeData: *[],
 *    showAudience: boolean
 *  }}
 */
export default () => ({
  submitted: false,
  dropdownOptions: [],
  industryData: [],
  sizeData: [],
  audienceData: [],
  origData: [],
  benchmarkData: { industry: '', size: '', audience: '' },
  renderChart: true,
  showCalculate: false,
  showSize: false,
  showAudience: false,
  chartData: { csat: '', frt: '', mkbv: '', sup: '', mes: '', mtv: '' }, // Placeholder values - should be blank
  reset() {
    this.submitted = false;
  },
  industryChanged() {
    this.$nextTick(() => {
      // reset all descendants to empty, false, or blank
      this.benchmarkData.size = '';
      this.benchmarkData.audience = '';
      this.showAudience = false;
      this.showCalculate = false;
      this.showSize = false;
      this.industryData = [];
      this.sizeData = [];
      this.audienceData = [];

      /* When the industry changes, we need to save the child elements
      of the industry data and update the dropdowns. */
      let currentData = this.origData[this.benchmarkData.industry];
      if (currentData) {
        this.industryData = currentData;
        if(currentData.small || currentData.medium || currentData.large) {
          // only show the next dropdown if at least one of these children exist
          this.showSize = true;
        }
        if(currentData.dropdownOptions) {
          this.dropdownOptions = currentData.dropdownOptions;
        }
      }
    });

  },
  sizeChanged() {
    this.$nextTick(() => {
      // reset all descendants to empty, false, or blank
      this.benchmarkData.audience = '';
      this.audienceData = [];
      this.showAudience = false;
      this.showCalculate = false;

      /* When the size changes, we need to save the child elements
      of the size data and update the dropdowns. We also need to start
      considering here if the data is ready to be shown. */
      let currentData = this.industryData[this.benchmarkData.size];
      if (currentData) {
        this.sizeData = currentData;
        if(currentData.data) {
          // only show the next dropdown if at least one of these children exist
          this.showCalculate = true;
        } else {
          if(currentData.businesses || currentData.customers || currentData.internal) {
            this.showAudience = true;
          }
          if(currentData.dropdownOptions) {
            this.dropdownOptions = currentData.dropdownOptions;
          }
        }
      }
    });

  },
  audienceChanged() {
    this.$nextTick(() => {

      /* When the audience changes, we need to save the child elements
      of the audience data and update the dropdowns. We also need to start
      considering here if the data is ready to be shown. */
      let currentData = this.sizeData[this.benchmarkData.audience];
      if (currentData) {
        this.audienceData = currentData;
        if(currentData.data) {
          this.showCalculate = true;
        } else {
          if(currentData.businesses || currentData.customers || currentData.internal) {
            this.showAudience = true;
          }
          if(currentData.dropdownOptions) {
            this.dropdownOptions = currentData.dropdownOptions;
          }
        }
      }
    });
  },
  smoothScroll() {
    this.$nextTick(() => {
      const el = document.querySelector('#benchmark');
      el.scrollIntoView({
        behavior: "smooth"
      });
    })
  },
  submit() {

    /* Because of our dataset, data is only ready to be shown after
    either audience or size is selected. Therefore, we will go in
    reverse order to update the chart information. */
    if(this.audienceData.data) {
      this.chartData = this.audienceData.data;
    } else if(this.sizeData.data) {
      this.chartData = this.sizeData.data;
    }
    this.submitted = true;
    this.renderChart = true;
    this.smoothScroll();
  },
});
